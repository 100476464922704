<script setup lang="ts">
import {
  FieldParagraphDownloadsFieldDownloadItems,
  FileFieldsFragment,
  MediaDocument,
  ParagraphDownloads,
} from '~/api/graphql/generated'

const props = defineProps<{
  entity: ParagraphDownloads
}>()

const downloads =
  props.entity.fieldDownloadItems
    ?.filter(Boolean)
    .filter((item) => item.entity)
    .map((file: FieldParagraphDownloadsFieldDownloadItems) => {
      const entity = file.entity as MediaDocument
      const document = entity.fieldMediaDocument?.entity as FileFieldsFragment

      const title = entity.name || document.filename || ''
      const type = document.filemime || ''
      const size = document.filesize || ''
      const url = document.url || ''

      return {
        title,
        type,
        size,
        url,
      }
    }) || []
</script>

<template>
  <Section>
    <div class="t-10 t-o-1 tl-7 tl-o-0 ds-o-1">
      <h2 v-if="entity.fieldTitle" v-animate="{ animation: 'vertical-reveal' }">{{ entity.fieldTitle }}</h2>
      <Downloads :items="downloads" />
    </div>
  </Section>
</template>
